
import $ from 'jquery';
import '../../node_modules/jquery/dist/jquery.slim.min';

/*ANIMATE*/
import '../../node_modules/aos/dist/aos.css';
import '../../node_modules//aos/dist/aos.js';
import './animate.js';
/* END ANIMATE */
import '../../node_modules/swiper/dist/css/swiper.min.css';
import '../../node_modules/swiper/dist/js/swiper.min';
import '../../node_modules/@fortawesome/fontawesome-free/js/all.min';
import '../css/main.scss';
import './scroll-nav';
import './burger';
import './swiper-partners';
import './scrollToTop';
import './navActiveLink';
import './preloader';












